import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule, MatMenuModule} from '@angular/material';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { NavComponent } from './components/nav/nav.component';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { SummaryComponent } from './components/summary/summary.component';
import { ContactsComponentComponent } from './components/contacts-component/contacts-component.component';
import { EducationComponentComponent } from './components/education-component/education-component.component';
import { TechnicalSkillsWidgetComponent } from './components/technical-skills-widget/technical-skills-widget.component';
// tslint:disable-next-line:max-line-length
import { ProfessionalExperiencesWidgetComponent } from './components/professional-experiences-widget/professional-experiences-widget.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ContentContainerComponent,
    SummaryComponent,
    ContactsComponentComponent,
    EducationComponentComponent,
    TechnicalSkillsWidgetComponent,
    ProfessionalExperiencesWidgetComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
