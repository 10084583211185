import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'professional-experiences-widget',
  templateUrl: './professional-experiences-widget.component.html',
  styleUrls: ['./professional-experiences-widget.component.scss']
})
export class ProfessionalExperiencesWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
