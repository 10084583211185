import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {NavbarItem} from '../../beans/navbarItem';

@Component({
  selector: 'nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  public navbarItems: NavbarItem[] = [];
  public showSidebar = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {
    this.navbarItems = [
      {
        title: 'About Me',
        link: '#'
      },
      {
        title: 'Academic Experiences',
        link: '#'
      },
      {
        title: 'Professional Experiences',
        link: '#'
      },
      {
        title: 'Bootcamp',
        link: '#'
      },
      {
        title: 'Blog',
        link: '#'
      },
      {
        title: 'Stay in touch',
        link: '#'
      }
    ];
  }

  close() {
    console.log('closed!');
    this.showSidebar = false;
  }
}
